import { Action } from './../actions/projectActions';

const initState = {
  rootId: 0,
  items: {},
  isLoading: false,
  showingProjects: false,
  isDeletingProject: false,
  deleteError: {}
};

const projectReducer = (state = initState, action) => {
  switch (action.type) {

    case Action.ADD_PROJECTS: {
      const items = { ...state.items, ...action.items };
      return { ...state, items };
    }

    case Action.LOADING_PROJECTS: {
      return { ...state, isLoading: action.isLoading }
    }

    case Action.SET_CHILDREN: {
      let newState = { ...state };
      const { projectId, children } = action;
      let project = newState.items[projectId];
      if (project) {
        project.children = children;
      }
      return newState;
    }

    case Action.SET_ACTIVE_PROJECT: {
      const { projectId, isActive } = action;
      if (projectId) {
        return {
          ...state,
          items: {
            ...state.items,
            [projectId]: {
              ...state.items[projectId],
              data: {
                ...state.items[projectId].data,
                isActive
              }
            }
          }
        }
      }
      break;
    }

    case Action.SHOW_PROJECTS: {
      return { ...state, showingProjects: action.showingProjects }
    }

    case Action.DELETE_PROJECT: {
      let items = { ...state.items };
      const { projectId } = action;
      if (projectId) {
        const parentId = items[projectId]?.data?.parentProjectId ?? 0;
        if (parentId !== undefined) {
          let children = items[parentId]?.children ?? [];
          items[parentId].children = children.filter(id => id !== projectId);
        }
        delete items[projectId];
      }
      return { ...state, items };
    }

    case Action.IS_DELETING_PROJECT:
      return { ...state, isDeletingProject: action.isDeletingProject }
    case Action.SET_DELETE_ERROR:
      return { ...state, deleteError: action.deleteError }

    case Action.TOGGLE_EXPANDED: {
      let newState = { ...state };
      let project = newState.items[action.projectId];
      if (project) {
        project.isExpanded = !project.isExpanded;
        return newState;
      }
      return { ...state };
    }

    case Action.SET_CHILDREN_LOADING: {
      const { projectId, isLoading } = action;
      return {
        ...state,
        items: {
          ...state.items,
          [projectId]: {
            ...state.items[projectId],
            isChildrenLoading: isLoading
          }
        }
      }
    }
    case Action.SET_CHILDREN_EXPANDED: {
      const { projectId, isExpanded } = action;
      return {
        ...state,
        items: {
          ...state.items,
          [projectId]: {
            ...state.items[projectId],
            isExpanded: isExpanded
          }
        }
      }
    }
    case Action.SET_PROJECT_WB_HEADER_COLOUR: {
      const { projectId, headerColour } = action;
      return {
        ...state,
        items: {
          ...state.items,
          [projectId]: {
            ...state.items[projectId],
            data: {
              ...state.items[projectId].data,
              headerColour
            }
          }
        }
      }
    }
    case Action.SET_HAS_CHILDREN: {
      const { projectId, hasChildren } = action;
      return {
        ...state,
        items: {
          ...state.items,
          [projectId]: {
            ...state.items[projectId],
            hasChildren
          }
        }
      }
    }
    default:
      return state;
  }
};

export default projectReducer;


// .headerColour = hasChildren ? randomHexLightColorCode() : ""