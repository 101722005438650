import { setAuthenticated } from "./../store/actions/user/index";
import store from "../store";


const HEADER = {
  XSRF_TOKEN: "XSRF-TOKEN",
  X_XSRF_TOKEN: "X-XSRF-TOKEN"
}

const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
  "Accept": "application/json"
};

const CREDENTIALS = {
  SAME_ORIGIN: "same-origin"
};

const getCookie = name => {
  var cookieArr = document.cookie?.split(";");
  if (Array.isArray(cookieArr)) {
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");
      if (name === cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
  }
  return null;
}

const addXsrfToHeader = () => {
  const csrf = getCookie(HEADER.XSRF_TOKEN);
  if (csrf) {
    DEFAULT_HEADERS[HEADER.X_XSRF_TOKEN] = csrf;
  } else {
    delete DEFAULT_HEADERS[HEADER.X_XSRF_TOKEN];
  }
}

/**
 * @deprecated
 * @param {string} url 
 */
async function get(url = "") {
  const response = await fetch(url, {
    method: "GET",
    mode: "cors",
    credentials: CREDENTIALS.SAME_ORIGIN,
    headers: DEFAULT_HEADERS
  });
  handle401(response);
  return await response.json();
}

async function httpGet(url = "", data) {
  const response = await fetch(url, {
    method: "GET",
    credentials: CREDENTIALS.SAME_ORIGIN,
    headers: DEFAULT_HEADERS,
    body: JSON.stringify(data)
  });
  handle401(response);
  return response;
}

async function httpDelete(url = "", id) {
  addXsrfToHeader();
  if (id) {
    url = `${url}/${id}`;
  }
  const response = await fetch(`${url}`, {
    method: "DELETE",
    credentials: CREDENTIALS.SAME_ORIGIN,
    headers: DEFAULT_HEADERS
  });
  handle401(response);
  return response;
}

async function put(url = "", data) {
  addXsrfToHeader();
  const response = await fetch(url, {
    method: "PUT",
    credentials: CREDENTIALS.SAME_ORIGIN,
    headers: DEFAULT_HEADERS,
    body: JSON.stringify(data)
  });
  handle401(response);
  return response;
}

async function post(url = "", data = {}, handleError) {
  addXsrfToHeader();
  const response = await fetch(url, {
    method: "POST",
    credentials: CREDENTIALS.SAME_ORIGIN,
    headers: DEFAULT_HEADERS,
    body: JSON.stringify(data)
  });
  handle401(response);
  return response;
}

async function patch(url = "", data = {}, handleError) {
  addXsrfToHeader();
  const response = await fetch(url, {
    method: "PATCH",
    credentials: CREDENTIALS.SAME_ORIGIN,
    headers: DEFAULT_HEADERS,
    body: JSON.stringify(data)
  });
  handle401(response);
  return response;
}

const handle401 = response => {
  if (response && response.status === 401) {
    store.dispatch(setAuthenticated(false))
  }
}

export {
  get,
  httpGet,
  httpDelete,
  post,
  put,
  patch,
  getCookie,
  HEADER
};