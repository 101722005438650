import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { Folder, FolderOpen } from "@material-ui/icons";
import { POST_TYPE } from "../../../util/constants";
import { randomId } from "../../../util/util";
import { default as ProjectHeader } from "../Header";
import { DocumentPost } from "../../Post/DocumentPost";
import { ImagePost } from "../../Post/ImagePost";
import { useStyles } from "./useStyles";


const SelectedItems = ({ selected }) => {

  const history = useHistory();
  const classes = useStyles();
  const { data, loading } = useSelector((state) => state.userProjects.projects);

  const getPostComponent = (postData, position) => {
    switch (postData?.postType) {
      case POST_TYPE.RICH_TEXT:
        return <DocumentPost key={postData.id} post={postData} position={position} />
      case POST_TYPE.IMAGE:
        return <ImagePost key={postData.id} post={postData} position={position} />
      default:
        return null
    }
  }

  const getPostComponents = (postItems = []) => {

    const posts = [];
    let index = 0;
    for (let i = 0; i < postItems.length; i++) {
      const item = postItems[i];
      const data = item?.data;
      console.log(data?.displayProperties);
      if (data?.displayProperties?.hideHeader) {
        index++;
      } else {
        index = 0;
      }
      posts.push(getPostComponent(data, index));
    }
    return posts;
    // return postItems.map(item => getPostComponent(item?.data));
  }

  const getProjectsComponent = (projects = []) => {
    return (
      <Grid key={randomId()} container direction="row" justifyContent="center" alignItems="center" spacing={1} className={classes.projectsContainer}>
        {projects.map(item => {
          const { isExpanded } = item;
          const project = item?.data;
          const { id, name } = project;
          if (!project?.postType) {
            return (
              <Grid item className={classes.projectItem} xs key={id} onClick={() => history.push(`/profile/${id}`)}>
                {item.children.length > 0 ? (
                  <Folder className={classes.projectIcon} />
                ) : (
                  <FolderOpen className={classes.projectIcon} />
                )}
                <Typography noWrap style={isExpanded ? { textTransform: "bold" } : {}}>{name}</Typography>
              </Grid>
            );
          }
          return <div key={id}></div>;
        })}
      </Grid>
    );
  }

  const getHeaderComponent = (projectData) => {
    return <ProjectHeader key={projectData?.id} project={projectData} />;
  }

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    )
  }

  /**
   * Check if given parentId is a parent if a id in tree hierarchy 
   * @param {*} id  id needs to be checked
   * @param {*} parentId - parentId that needs to be compared
   * @param {*} items - all items
   * @returns 
   */
  const isParent = (id, parentId, items) => {
    if (id && parentId && items) {
      let parent = items[id]?.data?.parentId;
      while (parent) {
        if (parentId === parent) {
          return true;
        }
        parent = items[parent]?.data?.parentId;
      }
    }
    return false;
  }

  /**
   * Check if given parentId is direct parent of a id
   * @param {*} id - id needs to be checked
   * @param {*} parentId - parentId that needs to be compared
   * @param {*} items - all items
   * @returns 
   */
  const isImmediateParent = (id, parentId, items) => {
    if (id && parentId && items) {
      let parent = items[id]?.data?.parentId;
      return parentId === parent;
    }
    return false;
  }

  const isProject = (itemId, items) => {
    return isPost(itemId, items) === false;
  }

  const isPost = (itemId, items) => {
    if (itemId && items) {
      if (items[itemId]?.data?.postType) {
        return true;
      }
    }
    return false;
  }

  const isParentHidden = (item, items) => {
    const parentId = item?.data?.parentId;
    let parent = parentId ? items[parentId] : {};
    let parentHidden = parent?.data?.displayProperties?.hideProjects;
    while (!parentHidden) {
      parent = items[parent?.data?.parentId];
      if (!parent) break;
      parentHidden = parent?.data?.displayProperties?.hideProjects;
    }
    return parentHidden;
  }


  const selectedProjectItems = [];

  const { items } = data;
  if (selected && items) {
    const ROOT_ID = data?.rootId;
    let itemId = selected;
    while (itemId) {
      let postItems = [], projectItems = [];
      const item = items[itemId];
      const parentId = item?.data?.parentId;
      let parent = items[parentId];

      let headerItem;
      if (isProject(itemId, items)) {
        if (itemId !== ROOT_ID && !parent?.data?.displayProperties?.hideProjects) {
          headerItem = item;
        }

        const projects = [], posts = [];

        const hidePosts = item?.data?.displayProperties?.hidePosts;
        const hideProjects = item?.data?.displayProperties?.hideProjects;
        const parentHidden = isParentHidden(item, items);

        const children = item?.children ?? [];
        for (const id of children) {

          const isSelectedItem = selected === id || isImmediateParent(id, selected, items); // || isParent(selected, id, items);

          if (isPost(id, items)) {

            if (isSelectedItem) {
              posts.push(items[id]);
            } else if (!hidePosts && !parentHidden) {
              posts.push(items[id]);
            }

          } else { // must be project

            if (isSelectedItem || isParent(selected, id, items)) {
              projects.push(items[id]);
            } else if (!hideProjects && !parentHidden) {
              projects.push(items[id]);
            }

          }
        }

        projectItems = projects;
        postItems = posts;
      }

      if (projectItems.length > 0) {
        selectedProjectItems.unshift(getProjectsComponent(projectItems));
      }
      if (postItems.length > 0) {
        selectedProjectItems.unshift(getPostComponents(postItems));
      }
      if (headerItem) {
        selectedProjectItems.unshift(getHeaderComponent(headerItem?.data));
      }

      itemId = parentId;
    }
  }


  return (selectedProjectItems);
}

export default SelectedItems;