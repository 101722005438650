export const SET_PROFILE_WORKBOX_HEIGTH = 'SET_PROFILE_WORKBOX_HEIGTH';
export const SET_SHOW_MENU = 'SET_SHOW_MENU';
export const SET_SHOW_UPPY = 'SET_SHOW_UPPY';
export const SET_SHOW_BUTTONS = 'SET_SHOW_BUTTONS';
export const SHOW_PROJECTS_LIST = 'SHOW_PROJECTS_LIST';

export const setProfileWorkboxHeight = (height) => {
  return { type: SET_PROFILE_WORKBOX_HEIGTH, height }
}

export const setShowMenu = (showMenu) => {
  return { type: SET_SHOW_MENU, showMenu }
}

export const setShowUppy = (showUppy) => {
  return { type: SET_SHOW_UPPY, showUppy }
}

export const setShowButtons = (showButtons) => {
  return { type: SET_SHOW_BUTTONS, showButtons }
}

// export const setProfileDescription = (profileDescription) => {
//   return { type: SET_PROFILE_DESCRIPTION, profileDescription };
// }

export const setShowProjectsList = (showProjectsList) => {
  return { type: SHOW_PROJECTS_LIST, showProjectsList };
}

// export const saveProfileDescription = (profileDescription) => {

//   return async (dispatch) => {
//     try {
//       const response = await post('/api/v1/user/save-description.php', { content: profileDescription });
//       const json = await response.json();
//       if (json && json.data && json.data.content) {
//         dispatch(setProfileDescription(json.data.content));
//       }
//     } catch (e) {
//       console.log('failed to save description content');
//     }

//   }
// }
