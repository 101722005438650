import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { TextField, Button, CircularProgress, makeStyles, Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab"
import { post } from "../../../util/httpClient";
import { addProjectToTree } from "../../../store/projects/slice";

const useStyles = makeStyles(theme => ({
  formRoot: {
    margin: theme.spacing(0, 4, 2),
    padding: theme.spacing(4),
    width: theme.spacing(70)
  },
  formField: {
    marginTop: theme.spacing(2)
  },
  formDivider: {
    marginBottom: theme.spacing(2)
  },
  formBtn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formBtnBox: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3)
  }
}));

const NewDocumentPost = ({ projectId: selected }) => {

  // const { selected } = useSelector((state) => state.userProjects.projects);

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [msg, setMsg] = useState("");
  const dispatch = useDispatch();

  const findErrMsg = (field, errors) => {
    return errors.find(err => err.field === field)?.message;
  }

  const hasAnError = (field, errors) => {
    return errors.find(err => err.field === field)?.message !== undefined;
  }

  const { handleSubmit, setFieldValue, getFieldProps } = useFormik({
    initialValues: {
      name: ""
    },
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      try {
        if (selected && selected !== "0") {
          values.projectId = selected;
        }
        const response = await post("/api/v1/project/post/document", values);
        setLoading(false);
        setSubmitting(false);
        const json = await response.json();
        if (response.ok) {
          dispatch(addProjectToTree({ parentProjectId: selected, project: json.results }));
          setMsg("Created a new project.");
          setFieldValue("name", "");
        } else {
          if (response.status === 400) {
            if (json.errors && Array.isArray(json.errors)) {
              setErrors(json.errors)
            }
          }
        }
      } catch (e) {
        setLoading(false);
        setSubmitting(false);
        console.log(e)
      }
    }
  });

  const handleChange = (e) => {
    if (msg) {
      setMsg("");
    }
    if (Array.isArray(errors) && errors.length > 0) {
      setErrors([]);
    }
    setFieldValue(e.target.name, e.target.value);
  }

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off" className={classes.formRoot}>
        {msg ? <Alert severity="success">{msg}</Alert> : null}
        <TextField className={classes.formField} fullWidth variant="outlined" type="text" id="name" name="name" label="Name" placeholder="New Document Post Name" autoFocus {...getFieldProps("name")} error={hasAnError("name", errors)} helperText={findErrMsg("name", errors)} onChange={handleChange} />
        <Box className={classes.formBtnBox}>
          <Button className={classes.formButton} size="large" type="submit" variant="contained" color="primary" disabled={loading}>{loading ? <CircularProgress size={30} thickness={6} /> : "Create Post"}</Button>
        </Box>
      </form>
    </>
  );
}



export default NewDocumentPost;