export const Action = {
  SET_SHOW_MAIN_SLIDER: 'SET_SHOW_MAIN_SLIDER',
  SET_MAIN_SLIDER_SIZE: 'SET_MAIN_SLIDER_SIZE'
}

export const setShowMainSlider = (showMainSlider) => {
  return { type: Action.SET_SHOW_MAIN_SLIDER, showMainSlider };
}

export const setMainSliderSize = (mainSliderSize) => {
  return { type: Action.SET_MAIN_SLIDER_SIZE, mainSliderSize };
}