import React, { Fragment } from "react";
import { Checkbox, Chip, CircularProgress, Tooltip } from "@material-ui/core";
import { BsPencilSquare } from "react-icons/bs";
import { FaCog } from "react-icons/fa";
import Slider, { SLIDER_MEDIUM } from "../Slider";
import useStyles from "./useStyles.js";



export const WB_SIZE = {
  MIN: "MIN",
  MED: "MED",
  DEFAULT: "",
}

export function WorkboxContainer({ children, style, innerRef }) {
  const classes = useStyles();
  return (
    <div
      className={classes.container}
      ref={innerRef ? innerRef : null}
      style={style}
    >
      {children}
    </div>
  );
}

export function WorkboxSlider({ children }) {
  return (
    <Slider size={SLIDER_MEDIUM}>
      {children}
    </Slider>
  );
}


export const calcWorkboxHeights = (currentWbHeight, maxWbHeight) => {
  let newHeight = "";
  switch (currentWbHeight) {
    case WB_SIZE.MIN: {
      newHeight = maxWbHeight;
      break;
    }
    case WB_SIZE.MED: {
      if (maxWbHeight === WB_SIZE.MED) {
        newHeight = WB_SIZE.MIN;
      }
      if (maxWbHeight === WB_SIZE.DEFAULT) {
        newHeight = WB_SIZE.DEFAULT;
      }
      break;
    }
    case "":
    case WB_SIZE.DEFAULT: {
      if (maxWbHeight === WB_SIZE.DEFAULT) {
        newHeight = WB_SIZE.MIN;
      }
      if (maxWbHeight === WB_SIZE.MED) {
        newHeight = WB_SIZE.MED;
      }
      break;
    }
    default: { }
  }
  return newHeight;
}


export function WorkboxHeader(props) {
  const {
    innerRef,
    className = "",
    loading,
    standAlone,
    // headerColour,

    minimize = false,
    title,
    hideTitleOnMinimize = false,
    onHideTitleChange,
    onTitleEditClick,
    onTitleClick,
    onCogClick,

    onLeftHeaderSpaceClick,
    onRightHeaderSpaceClick,
    headerIconsRight,
    headerIconsLeft,
    // headerMiddleLeftItems,
  } = props;

  const classes = useStyles({ standAlone });

  const handleLeftHeaderSpaceClick = () => {
    if (onLeftHeaderSpaceClick) {
      onLeftHeaderSpaceClick();
    }
  }

  const handleRightHeaderSpaceClick = (e) => {
    e.preventDefault();
    if (onRightHeaderSpaceClick) {
      onRightHeaderSpaceClick();
    }
  }

  const handleEditTitleClick = (event) => {
    event.preventDefault();
    if (onTitleEditClick) {
      onTitleEditClick();
    }
  }

  const handleCogClick = (event) => {
    event.preventDefault();
    if (onCogClick) {
      onCogClick();
    }
  }

  const handleTitleClick = (e) => {
    e.preventDefault();
    if (onTitleClick) {
      onTitleClick();
    }
  }

  const HeaderTitle = ({ title }) => {
    return (
      <div className={classes.wbCardHeaderCenter}>
        <div className={`${classes.wbCardHeaderTitle} ${classes.cPointer}`} onClick={handleTitleClick} title="click to hide/show content">
          <Chip label={title} className={`${classes.headerTitle} ${onTitleClick && classes.cPointer}`} />
        </div>
      </div>
    );
  }

  const HeaderCog = ({ loading }) => {
    if (loading) {
      return <CircularProgress size={25} />
    }
    return <FaCog size={22} onClick={handleCogClick} className={classes.icon} />
  }

  const handleHideTitleChange = (e) => {
    if (onHideTitleChange) {
      onHideTitleChange(e.target.checked);
    }
  }



  if (minimize) {

    if (hideTitleOnMinimize) {
      return (
        <div ref={innerRef} className={`${classes.wbCardHeader} ${className} ${classes.wbCardHeaderMinTitle}`} >
          <div className={classes.wbCardHeaderSide}>
            <div className={classes.wbCardHeaderIcon}>
              <HeaderCog loading={loading} />
            </div>
          </div>
          <HeaderTitle title={title} />
          <div className={classes.wbCardHeaderSide}>
          </div>
        </div>
      );
    }

    return (
      <Tooltip title={title} placement="right" arrow>
        <div ref={innerRef} className={`${classes.wbCardHeader} ${className} ${classes.wbCardHeaderMin}`}>
          <HeaderCog loading={loading} />
        </div>
      </Tooltip>
    );
  }

  return (
    <div ref={innerRef} className={`${classes.wbCardHeader} ${className}`}>

      <div className={classes.wbCardHeaderSide}>
        <div className={classes.wbCardHeaderIcon}>
          <HeaderCog loading={loading} />
          {headerIconsLeft && headerIconsLeft.map((icon, i) => <Fragment key={i}>{icon}</Fragment>)}
        </div>
        <div className={classes.wbCardHeaderSpace} onClick={handleLeftHeaderSpaceClick} title="hide/show main timeline"></div>
        <div>
          <Checkbox color="default" checked={hideTitleOnMinimize} onChange={handleHideTitleChange} className={classes.titleCheckbox} title={hideTitleOnMinimize ? "untick to hide title" : "tick to show title"} />
        </div>
      </div>

      <HeaderTitle title={title} />

      <div className={classes.wbCardHeaderSide}>
        <div className={classes.wbCardHeaderIcon}>
          <BsPencilSquare size={22} onClick={handleEditTitleClick} className={classes.icon} title="edit" />
        </div>
        <div className={classes.wbCardHeaderSpace} onClick={handleRightHeaderSpaceClick} title="resize"></div>
        <div className={classes.wbCardHeaderIcon}>
          {headerIconsRight && headerIconsRight.map((icon, i) => <Fragment key={i}>{icon}</Fragment>)}
        </div>
      </div>

    </div>
  );
}

export function WorkboxBody({ children, height }) {
  const classes = useStyles();

  let className = "";
  switch (height) {
    case WB_SIZE.MIN:
      className = classes.wbCardMin;
      break;
    case WB_SIZE.MED:
      className = classes.wbCardMed;
      break;
    default:
  }

  return (
    <div className={`${className}`}>
      {children}
    </div>
  );
}

const Workbox = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={`card ${classes.wbCard}`} style={{ border: 0 }}>
      {children}
    </div>
  );
}

export default Workbox;