import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Toolbar, Drawer, Divider, Grid, List, ListItem, ListItemText, ListItemIcon, IconButton, CircularProgress, Button, ButtonGroup, Dialog, DialogTitle, DialogContent, Menu, MenuItem } from "@material-ui/core";
import { ChevronLeft, ChevronRight, Folder, FolderOpen, DescriptionOutlined, DeleteForever, ImageOutlined } from "@material-ui/icons";
import Tree, { mutateTree } from "@atlaskit/tree";
import { getProjectsTree, updateTree, deleteItemFromTree } from "../../store/projects/slice";
import { POST_TYPE, DIALOG } from "../../util/constants";
import { httpDelete } from "../../util/httpClient";
import CreateNewProject from "../../components/Project/CreateNewProject";
import NewDocumentPost from "../../components/Post/Form/NewDocumentPost";
import NewImagePost from "../../components/Post/Form/NewImagePost";
import SelectedItems from "../../components/Project/SelectedItems";
import { useStyles } from "./useStyles";

// import ProfileWorkbox from '../components/profile/ProfileWorkbox';
// import ProjectsSlider from '../components/projects/ProjectsSlider';
// import ProjectsList from '../components/projects/ProjectsList';
// import ProjectsTree from '../components/projects/ProjectsTree';


export const MAIN_SLIDER_SIZE = {
  MED: "MED",
  MAX: "MAX",
};

const PADDING_PER_LEVEL = 16;


function Profile() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.userProjects.projects);

  const ROOT_ID = data.rootId;
  const { project: selected = ROOT_ID } = useParams();

  // local state
  const [openDrawer, setOpenDrawer] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [newAchorEl, setNewAchorEl] = useState(null);
  const [dialog, setDialog] = useState({
    open: false,
    for: "",
    title: ""
  });

  const disableCreatePostBtn = !selected;

  useEffect(() => {
    dispatch(getProjectsTree());
  }, [dispatch]);

  const onExpand = (itemId) => {
    const newTree = mutateTree(data, itemId, { isExpanded: true });
    dispatch(updateTree(newTree));
  };

  const onCollapse = (itemId) => {
    const newTree = mutateTree(data, itemId, { isExpanded: false });
    dispatch(updateTree(newTree));
  };

  const getIcon = (item, onExpand, onCollapse) => {
    if (item.children && item.children.length > 0) {
      return item.isExpanded ? (
        <Folder onClick={() => onCollapse(item.id)} />
      ) : (
        <Folder onClick={() => onExpand(item.id)} />
      );
    }

    switch (item?.data?.postType) {
      case POST_TYPE.RICH_TEXT:
        return <DescriptionOutlined />
      case POST_TYPE.IMAGE:
        return <ImageOutlined />
      default:
      // fall through
    }

    return <FolderOpen />;
  };

  const handleSelect = (newSelected) => {
    if (selected === newSelected || newSelected === ROOT_ID) {
      history.push(`/profile/`);
    } else {
      history.push(`/profile/${newSelected}`);
    }
  };

  const handleTreeItemClick = (itemId) => {
    handleSelect(itemId);
  };

  const renderItem = ({ item, onExpand, onCollapse, provided, snapshot }) => {
    const { id, name } = item.data;
    const icon = getIcon(item, onExpand, onCollapse);
    return (
      <div ref={provided.innerRef} {...provided.draggableProps}>
        <ListItem
          button
          selected={id === selected}
          className={`${classes.treeListItem} ${id === selected && classes.treeListItemSelected}`}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={name} onClick={() => handleTreeItemClick(id)} />
        </ListItem>
      </div>
    );
  };

  if (loading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    )
  }

  const handleOnDeleteClick = async () => {
    setDeleting(true);
    const postData = data?.items?.[selected]?.data;
    let apiURL;
    switch (postData?.postType) {
      case POST_TYPE.RICH_TEXT:
        apiURL = `/api/v1/project/post/document/${postData.id}`;
        break;
      case POST_TYPE.IMAGE:
        apiURL = `/api/v1/project/post/file/${postData.id}`;
        break;
      default:
        apiURL = `/api/v1/project/${selected}`;
        break;
    }

    if (apiURL) {
      try {
        const response = await httpDelete(apiURL);
        if (response.ok) {
          dispatch(deleteItemFromTree(selected));
          const parentId = findParentId(selected, data);
          handleSelect(parentId);
        }
      } catch (e) {
      }
    }

    setDeleting(false);
  }

  const findParentId = (projectId, tree) => {
    const treeItems = tree?.items ?? {};

    const parentId = treeItems[projectId]?.parentId;
    if (parentId) return parentId;

    for (const [key, value] of Object.entries(treeItems)) {
      const children = value?.children;
      if (Array.isArray(children)) {
        if (children.includes(projectId)) {
          return key;
        }
      }
    }
    return "";
  }

  const handleNewMenuOnClose = () => {
    setNewAchorEl(null)
  }

  const handleNewProjectClick = () => {
    setDialog({
      ...dialog,
      open: true,
      for: DIALOG.PROJECT,
      title: "Create New Project"
    });

    setNewAchorEl(null);
  }

  const handleNewDocumentClick = () => {
    if (disableCreatePostBtn) {
      return;
    }
    setDialog({
      ...dialog,
      open: true,
      for: DIALOG.DOCUMENT_POST,
      title: "Create New Document Post"
    });

    setNewAchorEl(null);
  }

  const handleNewImagePostClick = () => {
    if (disableCreatePostBtn) {
      return;
    }
    setDialog({
      ...dialog,
      open: true,
      for: DIALOG.IMAGE_POST,
      title: "Create New Image Post"
    });

    setNewAchorEl(null);
  }

  const dialogContent = (dialogFor, selected) => {
    switch (dialogFor) {
      case DIALOG.PROJECT:
        return <CreateNewProject projectId={selected} />
      case DIALOG.DOCUMENT_POST:
        return <NewDocumentPost projectId={selected} />
      case DIALOG.IMAGE_POST:
        return <NewImagePost projectId={selected} />
      default:
        return <></>
    }
  }

  const selectedProject = data?.items?.[selected];
  const isPost = POST_TYPE.LIST.includes(selectedProject?.data?.postType);

  let disableDelete = true;
  if (!deleting && selected && selected !== ROOT_ID) {
    if (isPost) {
      disableDelete = false;
    } else {
      if (selectedProject) {
        if (selectedProject?.data?.parentId !== ROOT_ID) {
          disableDelete = false;
        } else if (!["Projects", "Media"].includes(selectedProject?.data?.name)) {
          disableDelete = false;
        }
      }
    }
  }

  let tree = data;
  if (!loading && selected && selected !== ROOT_ID && tree && Object.keys(tree).length > 0) {
    let itemId = findParentId(selected, tree);
    while (itemId) {
      tree = mutateTree(tree, itemId, { isExpanded: true });
      itemId = findParentId(itemId, tree);
    }
  }


  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={openDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <Divider />
        <div className={classes.drawerBtnContainer}>
          <ButtonGroup variant="text" size="small" className={classes.drawerBtnGroup}>
            <Button variant="contained" className={classes.drawerBtn} disabled={isPost} onClick={(e) => setNewAchorEl(e.target)}>New</Button>
            <Button variant="contained" className={`${classes.drawerBtn} ${classes.deleteBtn}`} disabled={disableDelete} onClick={handleOnDeleteClick}>
              {deleting ? (
                <CircularProgress size={24} />
              ) : (
                <DeleteForever />
              )}
            </Button>
          </ButtonGroup>
          <Menu
            id="add-new"
            variant="menu"
            anchorEl={newAchorEl}
            open={Boolean(newAchorEl)}
            onClose={handleNewMenuOnClose}
          >
            <MenuItem onClick={handleNewProjectClick}>
              <FolderOpen className={classes.menuItemIcon} />Project
            </MenuItem>
            <MenuItem onClick={handleNewDocumentClick} disabled={disableCreatePostBtn}>
              <DescriptionOutlined className={classes.menuItemIcon} />Document Post
            </MenuItem>
            <MenuItem onClick={handleNewImagePostClick} disabled={disableCreatePostBtn}>
              <ImageOutlined className={classes.menuItemIcon} />Image Post
            </MenuItem>
          </Menu>
          <IconButton
            color="inherit"
            aria-label="close drawer"
            onClick={() => setOpenDrawer(false)}
            className={`${classes.drawerCloser} ${!openDrawer && classes.hide}`}
          >
            <ChevronLeft />
          </IconButton>
        </div>
        <Divider />
        <List dense={true}>
          {data?.rootId && (
            <Tree
              tree={tree}
              renderItem={renderItem}
              onExpand={onExpand}
              onCollapse={onCollapse}
              offsetPerLevel={PADDING_PER_LEVEL}
            />
          )}
        </List>
      </Drawer>
      <IconButton
        color="inherit"
        aria-label="close drawer"
        onClick={() => setOpenDrawer(true)}
        className={`${classes.drawerOpener} ${openDrawer && classes.hide}`}
      >
        <ChevronRight />
      </IconButton>

      {/* <div className={classes.content}>
        <ProjectsSlider />
        <ProfileWorkbox />
        <ProjectsTree />
      </div> */}
      <div className={classes.content}>
        <Divider />
        {loading ? (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        ) : (
          <SelectedItems selected={selected} />
        )}

        {dialog?.for &&
          <Dialog open={dialog.open} onClose={() => setDialog({ ...dialog, open: false })} maxWidth="md">
            <DialogTitle>{dialog.title}</DialogTitle>
            <DialogContent>
              {dialogContent(dialog.for, selected)}
            </DialogContent>
          </Dialog>
        }

      </div>
    </>
  );
}

export default Profile;
