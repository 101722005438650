import { makeStyles } from "@material-ui/core";
import { DRAWER_WIDTH } from "../Profile/useStyles";

const useStyles = makeStyles(theme => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: "white",
        color: "black",
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        marginLeft: DRAWER_WIDTH,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    usernameChip: {
        minWidth: theme.spacing(14),
        margin: theme.spacing(0, 2),
        fontWeight: "600",
        fontSize: "0.9rem"
    },
    exploreChip: {
        minWidth: theme.spacing(16),
        margin: theme.spacing(0, 2),
        fontWeight: "600",
        fontSize: "1rem"
    }
}));

export { useStyles };
