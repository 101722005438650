import { makeStyles } from "@material-ui/core";

export const DRAWER_WIDTH = 260;

export const useStyles = makeStyles((theme) => ({
    drawer: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
    },
    drawerPaper: {
        width: DRAWER_WIDTH,
    },
    drawerOpener: {
        padding: theme.spacing(1),
        position: "absolute",
        top: theme.spacing(9),
        left: theme.spacing(1),
    },
    drawerCloser: {
        padding: theme.spacing(1),
        position: "absolute",
        top: theme.spacing(-0.5),
        left: theme.spacing(26),
    },
    drawerContainer: {
        overflow: "auto",
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },
    drawerBtnContainer: {
        display: "flex",
        justifyContent: "space-evenly",
        margin: "0.5rem",
        position: "relative"
    },
    drawerBtnGroup: {
        display: "flex",
        flexWrap: "wrap",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1)
        }
        // gap: "0.5rem"
    },
    drawerBtn: {
        flex: "auto"
        // color: "white",
        // backgroundColor: "#A7C1D5",
        // "&:hover": {
        //   backgroundColor: "#A7C1D5"
        // }
    },
    hide: {
        display: "none"
    },
    content: {
        position: "relative",
        flexGrow: 1,
        margin: `8px ${DRAWER_WIDTH}px`,
        padding: theme.spacing(0, 5),
    },
    treeListItem: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    treeListItemSelected: {
        fontSize: 16
        // color: "white",
        // backgroundColor: "#A7C1D5",
        // "&:hover": {
        //   backgroundColor: "#A7C1D5"
        // }
    },
    deleteBtn: {
        "&:hover": {
            backgroundColor: "red"
        }
    },
    menuItemIcon: {
        marginRight: theme.spacing(1.5)
    },
    menuItemDisbled: {
        // cursor: "default"
    },
    projectsContainer: {
        marginBottom: theme.spacing(2)
    },
    projectIcon: {
        fontSize: theme.spacing(8),
        cursor: "pointer"
    },
    projectItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: theme.spacing(20)
    }
}));