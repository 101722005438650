import { Action, LOCAL_STORAGE_AUTH } from "./../../actions/user"

const initState = {
  authenticated: localStorage.getItem(LOCAL_STORAGE_AUTH) ?? false,
  name: ""
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case Action.SET_AUTHENTICATED: {
      return {
        ...state,
        authenticated: action.authenticated
      }
    }
    case Action.SET_NAME: {
      return {
        ...state,
        name: action.name
      }
    }
    default:
      return state;
  }
};

export default userReducer;