import React, { useState } from "react";
import useDisplayState from "../../../hooks/post/useDisplayState";
import Workbox, { WorkboxContainer, WorkboxHeader, WorkboxBody, WB_SIZE, calcWorkboxHeights } from "../../Workbox";
import { useStyles } from "./useStyles";

const PostWorkbox = ({ post, children, onTitleEditClick, position }) => {

  const classes = useStyles({ position });

  const { id, name, displayProperties } = post;

  const { loading, updateHideHeader, updateHideTitle } = useDisplayState();

  const [wbHeight, setWBHeight] = useState(WB_SIZE.DEFAULT);

  const handleTitleClick = () => {
    const newWbHeigh = calcWorkboxHeights(wbHeight, WB_SIZE.DEFAULT);
    setWBHeight(newWbHeigh);
  }

  const handleRightHeaderSpaceClick = () => {
    const newWbHeight = calcWorkboxHeights(wbHeight, WB_SIZE.MED);
    setWBHeight(newWbHeight);
  }

  const handleOnTitleEditClick = () => {
    if (onTitleEditClick) {
      onTitleEditClick(id);
    }
  }

  const handleTitleHideChange = (hide) => {
    updateHideTitle(id, hide, post?.postType);
  }

  const standAlone = wbHeight === WB_SIZE.MIN;
  let containerStyle = (displayProperties?.hideHeader) ? { margin: "0" } : {};
  let headerStyle = (displayProperties?.hideHeader && standAlone) ? classes.headerMinCalculated : "";

  return (
    <WorkboxContainer style={containerStyle}>
      <Workbox>
        <WorkboxHeader
          standAlone={standAlone}
          loading={loading}
          title={name}
          hideTitleOnMinimize={displayProperties?.hideTitle}
          minimize={displayProperties?.hideHeader}
          onHideTitleChange={handleTitleHideChange}
          onTitleClick={handleTitleClick}
          onTitleEditClick={handleOnTitleEditClick}
          onCogClick={() => updateHideHeader(id, !displayProperties?.hideHeader, post?.postType)}
          // className={classes.workboxHeader}
          // loading={displayStateLoading}
          onRightHeaderSpaceClick={handleRightHeaderSpaceClick}
          className={headerStyle}
        />
        <WorkboxBody height={wbHeight}>
          {children}
        </WorkboxBody>
      </Workbox>
    </WorkboxContainer>
  );
}

export default PostWorkbox;