
export const LOCAL_STORAGE_AUTH = "auth";

export const Action = {
  SET_AUTHENTICATED: "SET_AUTHENTICATED",
  SET_NAME: "SET_NAME"
}

export const setAuthenticated = (authenticated) => {
  localStorage.setItem(LOCAL_STORAGE_AUTH, authenticated);
  return { type: Action.SET_AUTHENTICATED, authenticated };
}

export const setName = (name) => {
  return { type: Action.SET_NAME, name };
}