import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { POST_TYPE } from "../../../util/constants";
import { httpGet, patch, put } from "../../../util/httpClient";

const initialState = {
  projects: {
    data: {},
    loading: false,
    selected: ""
  }
};

const PROJECTS = "projects";

export const getProjectsTree = createAsyncThunk(`${PROJECTS}/getTree`, async (parentProjectId) => {
  try {
    let url = "/api/v1/project";
    if (parentProjectId) {
      url = `${url}/${parentProjectId}`;
    }
    const response = await httpGet(url);
    const json = await response.json();
    return json?.results ?? {};
  } catch (e) { }
});

export const updatePostContent = createAsyncThunk(`${PROJECTS}/updatePostContent`, async ({ postId, content }) => {
  try {
    const response = await put(`/api/v1/project/post/${postId}/content`, content);
    const json = await response.json();
    return {
      postId: postId,
      content: json?.results?.content,
    }
  } catch (e) { }
});

export const updateDisplayProperties = createAsyncThunk(`${PROJECTS}/updateDisplayProperties`, async ({ id, displayProperties, postType }) => {
  try {
    let url;
    switch (postType) {
      case POST_TYPE.RICH_TEXT:
        url = `/api/v1/project/post/document/${id}`;
        break;
      case POST_TYPE.IMAGE:
        url = `/api/v1/project/post/file/${id}`;
        break;
      default:
        url = `/api/v1/project/${id}`;
    }

    const response = await patch(url, { displayProperties: { ...displayProperties } });
    if (response.ok) {
      return {
        id,
        displayProperties
      }
    }
  } catch (e) {
  }
});

export const projectsSlice = createSlice({
  name: "userProjects",
  initialState,
  reducers: {
    updateTree: (state, action) => {
      state.projects.data = action.payload;
    },
    addProjectToTree: (state, action) => {
      const items = state.projects.data.items;
      const { parentProjectId, project } = action.payload;
      const projectId = project.id;

      const newItem = {
        id: projectId,
        hasChildren: false,
        children: [],
        data: project
      }

      if (project) {
        switch (project?.postType) {
          case POST_TYPE.RICH_TEXT:
            newItem.data.postType = POST_TYPE.RICH_TEXT;
            newItem.data.content = project?.content;
            break;
          case POST_TYPE.IMAGE:
            newItem.data.postType = POST_TYPE.IMAGE;
            newItem.data.url = project?.url;
            break;
          default:
          // fall through
        }
      }

      items[projectId] = newItem;

      let childrenAdded = false;
      // If is post type then move to end of the posts but before the projects/folders
      if (project?.postType) {
        const children = items[parentProjectId].children;
        if (Array.isArray(children)) {
          for (let i = 0; i < children.length; i++) {
            const itemId = children[i];
            if (!items[itemId]?.data?.hasOwnProperty("postType")) {
              items[parentProjectId].children.splice(i, 0, projectId);
              childrenAdded = true;
              break;
            }
          }
        }
      }

      if (!childrenAdded) {
        items[parentProjectId].children.push(projectId);
      }

      state.projects.data.items = items;
      // state.projects.selected = projectId;
    },
    deleteItemFromTree: (state, action) => {
      const itemToDelete = action.payload;
      const items = { ...state.projects.data.items };

      // find and delete the item from parent's children list
      Object.keys(items).forEach((itemId) => {
        const children = items[itemId]?.children || [];
        const filteredChildren = children.filter((id) => id !== itemToDelete);
        if (children.length !== filteredChildren.length) {
          state.projects.selected = itemId;
        }
        items[itemId].children = filteredChildren;
      });

      // @TODO if the project is deleted then should also delete all the children projects/posts

      // delete the items
      delete items[action.payload];

      state.projects.data.items = items;
    },
    setSelected: (state, action) => {
      state.projects.selected = action.payload;
    },
    updateContent: (state, action) => {
      const { postId, content } = action.payload;
      if (state.projects.data?.items[postId]?.data?.content !== undefined) {
        state.projects.data.items[postId].data.content = content;
      }
    },
    updatePostName: (state, action) => {
      const { postId, name } = action.payload;
      state.projects.data.items[postId].data.name = name;
    }
  },

  extraReducers: {
    [getProjectsTree.pending]: (state) => {
      state.projects.loading = true;
    },
    [getProjectsTree.fulfilled]: (state, { payload }) => {
      state.projects.loading = false;
      state.projects.data = { ...state.projects.data, ...payload };
    },
    [getProjectsTree.rejected]: (state) => {
      state.projects.loading = false;
    },

    [updateDisplayProperties.pending]: (state, { meta: { arg = {} } }) => {
      const { id, displayProperties } = arg;
      if (id && displayProperties) {
        state.projects.data.items[id].data.displayProperties = displayProperties;
      }
    },
    [updateDisplayProperties.fulfilled]: (state, { payload = {} }) => {
      const { id, displayProperties } = payload;
      if (id && displayProperties) {
        state.projects.data.items[id].data.displayProperties = displayProperties;
      }
    }

  }
});

export const {
  updateTree,
  setSelected,
  updateContent,
  deleteItemFromTree,
  addProjectToTree,
  updatePostName
} = projectsSlice.actions;
export default projectsSlice.reducer;
