import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ authenticated, component: Component, ...rest }) => {

  return (
    <Route {...rest} render={props => (
      authenticated
        ? <Component {...props} />
        : <Redirect to="/login" />
    )} />
  );
}

const mapStateToProps = (state) => {
  const { authenticated } = state.user;
  return {
    authenticated
  };
};

export default connect(mapStateToProps)(PrivateRoute);