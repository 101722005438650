import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { CssBaseline, } from "@material-ui/core";
import { ThemeProvider, createTheme, makeStyles, createStyles } from "@material-ui/core/styles";
import PrivateRoute from "./components/common/PrivateRoute";
import Header from "./pages/Header";
import Profile from "./pages/Profile";
import Explore from "./pages/Explore";
import Login from "./pages/Login";
import { httpGet } from "./util/httpClient";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { COLOR } from "./util/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    // borderRadius: "5em"
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  exploreChip: {
    minWidth: theme.spacing(16),
    margin: theme.spacing(2),
    fontWeight: "600",
    fontSize: "1rem"
  }
}));


const App = () => {

  const classes = useStyles();

  const theme = createTheme({
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    overrides: {
      MuiChip: createStyles({
        root: {
          padding: "0 16px",
          fontWeight: "bold",
          backgroundColor: COLOR.BG_PRIMARY.MAIN,
          color: "white",
          "&&:hover": {
            backgroundColor: COLOR.BG_PRIMARY.MAIN,
            color: "#f0f0f0"
          }
        },
        clickable: {
          "&&:focus": {
            backgroundColor: COLOR.BG_PRIMARY.MAIN
          }
        }
      }),
      MuiTooltip: {
        tooltip: {
          fontSize: "1em",
        },
      },
      // MuiCssBaseline: {
      //   '@global': {
      //     body: {
      //       backgroundColor: "#F2F2F2",
      //     },
      //   },
      // },
    },
    // palette: {
    //   primary: {
    //     main: COLOR.PRIMARY.MAIN,
    //     light: COLOR.PRIMARY.LIGHT,
    //     dark: COLOR.PRIMARY.DARK
    //   }
    // }
  });

  useEffect(() => {
    (async () =>
      await httpGet("/api/v1/auth/status")
    )();
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        <Header />
        <main>
          <Switch>
            <Route path={["/login", "/login/openbox", "/login/newbox"]} component={Login} />
            <Route path="/explore" component={Explore} />
            <PrivateRoute path="/profile/:project?" component={Profile} />
            {/* <Route path="/" component={Home} /> */}
          </Switch>
        </main>
      </div>
    </ThemeProvider>
  );
}

export default App;
