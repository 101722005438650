import { makeStyles } from "@material-ui/core";
import { COLOR } from "../../util/constants";

const useStyles = makeStyles(theme => ({
  container: {
    border: "none",
    margin: theme.spacing(1, 0)
  },
  headerTitle: {
    padding: theme.spacing(1),
    height: theme.spacing(3.6),
    userSelect: "none"
  },
  titleCheckbox: {
    padding: "0",
    margin: "0 9px",
    '&:hover': {
      backgroundColor: 'transparent',
      transform: "scale(1.14)"
    }
  },
  icon: {
    color: COLOR.BG_PRIMARY.MAIN
  },
  wbCardHeader: {
    borderRadius: props => props.standAlone ? "1.8rem" : "1rem 1rem 0 0",
    margin: "0 2px",
    minHeight: "2.25rem",
    background: "#e0e0e0",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "0"
  },
  wbCardHeaderMin: {
    // position: "relative",
    position: props => props.standAlone ? "relative" : "absolute",
    left: "-40px",
    // left: props => props.standAlone ? "-40px" : "2px",
    top: props => props.standAlone ? "0" : "25px",
    borderRadius: props => props.standAlone ? "" : "50%",
    height: "0",
    minHeight: "0",
    margin: "0",
    width: "2.25rem",
    background: "inherit",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      // transition: "0.2s",
      // transitionProperty: "transform",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.14)"
        // transform: "scale(1.14) rotate(30deg)"
      }
    }
  },
  wbCardHeaderMinTitle: {
    background: "transparent"
  },
  wbCardHeaderSide: {
    display: "flex",
    flex: "1",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row"
  },
  wbCardHeaderCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "0 0 auto"
  },
  wbCardHeaderSpace: {
    flex: "1",
    height: "100%",
    "&:hover": {
      cursor: "pointer"
    }
  },
  wbCardHeaderIcon: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    color: "#D3D3E1",
    margin: "0 5px",
    "& svg": {
      marginLeft: ".4rem",
      marginRight: ".4rem",
      transition: "all .1s",
      transformOrigin: "50% 50%",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.14)"
      }
    }
  },
  wbCard: {
    // overflow: "hidden",
    position: "relative",
  },
  wbCardMin: {
    display: "none"
  },
  wbCardMed: {
    maxHeight: "9rem",
    transition: "max-height 0.1s",
    overflowY: "scroll",
    overflowX: "hidden"
  },
  wbCardBody: {
    padding: "6px 0 0"
  },
  wbCardHeaderTitle: {
    display: "flex",
    justifyContent: "center",
    "& p": {
      fontSize: "1rem",
      margin: "0",
      padding: ".15em .5em"
    }
  },
  cPointer: {
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer"
    }
  }
}))


export default useStyles;