export function randomId() {
  return '_' + Math.random().toString(36).substr(2, 9);
};

export const randomHexColorCode = () => {
  let n = (Math.random() * 0xfffff * 1000000).toString(16);
  return '#' + n.slice(0, 6);
};

export const randomHexLightColorCode = () => {
  // var letters = 'BCDEF'.split('');
  // var color = '#';
  // for (var i = 0; i < 6; i++) {
  //   color += letters[Math.floor(Math.random() * letters.length)];
  // }
  // return color;

  return "hsl(" + Math.random() * 360 + ", 100%, 75%)";
}