import React, { useState } from "react";
import { useDispatch } from "react-redux";
import UppyDashboard from "../../UppyDashboard";
import { addProjectToTree } from "../../../store/projects/slice";
import { Alert } from "@material-ui/lab";


const CreateImagePost = ({ projectId: selected }) => {

  // const { selected } = useSelector((state) => state.userProjects.projects);

  const [msg, setMsg] = useState("");

  const dispatch = useDispatch();

  const handleUploadComplete = (response) => {
    const results = response?.body?.results;
    if (results) {
      dispatch(addProjectToTree({ parentProjectId: selected, project: results }));
      setMsg("New image post created. Upload an image to create another image post.");
    }
  }

  if (!selected || selected === "0") {
    return <></>;
  }

  return (
    <>
      {msg ? <Alert severity="success">{msg}</Alert> : null}
      <UppyDashboard
        // ref={this.uppyRef}
        uploadEndpoint={`/api/v1/project/${selected}/post/image`}
        onUploadComplete={handleUploadComplete}
        onAllUploadSuccess={() => { }}
      />
    </>
  );
}



export default CreateImagePost;