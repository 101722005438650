import React from "react";

import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/js/third_party/image_tui.min.js";

import FroalaEditorComponent from "react-froala-wysiwyg";
import { HEADER, getCookie } from "../../util/httpClient";


const editorKey = process.env.REACT_APP_TEXT_EDITOR_LIC_KEY;


function TextEditor(props) {
  
  const { placeholder, content, autoSaveURL, autoSaveMethod = "PUT", onContentChange } = props;

  const config = {
    toolbarInline: true,
    charCounterCount: false,
    placeholderText: placeholder,
    // heightMax: 470,
    // initOnClick: true, // does not work when toolbarInline: true
    attribution: false,
    key: editorKey,
    lineHeights: {
      Default: '1.3',
      Single: '1',
      '1.15': '1.15',
      '1.5': '1.5',
      Double: '2'
    },

    events: {
      // "initialized": function (e, editor) {
      // this.toolbar.show();
      // this.toolbar.showInline(null, false);
      // },
      "image.beforeUpload": function (files) {
        var editor = this;
        if (files.length) {
          // Create a File Reader.
          var reader = new FileReader();
          // Set the reader to insert images when they are loaded.
          reader.onload = function (e) {
            var result = e.target.result;
            editor.image.insert(result, null, null, editor.image.get());
          };
          // Read image as base64.
          reader.readAsDataURL(files[0]);
        }
        editor.popups.hideAll();
        // Stop default upload chain.
        return false;
      },
      // "save.after": function(responseString) {
      //   if (responseString) {
      //     const data = JSON.parse(responseString);
      //     console.log("save.after: data = " + JSON.stringify(data));
      //     console.log("save.after: content = " + data?.results?.content);
      //     handleContentChange(data?.results?.content);
      //   }
      // }
    }
  }

  const handleContentChange = (content) => {
    if (onContentChange) {
      onContentChange(content);
    }
  }


  if (autoSaveURL) {
    config.saveInterval = 500;
    config.saveParam = "content";
    config.saveURL = autoSaveURL;
    config.saveMethod = autoSaveMethod;
    config.requestHeaders = {
      [HEADER.X_XSRF_TOKEN]: getCookie(HEADER.XSRF_TOKEN)
    }
  }

  return (
    <FroalaEditorComponent
      tag="textarea"
      model={content}
      onModelChange={handleContentChange}
      config={config}
    />
  );
  

}

export default TextEditor;