import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    projectsContainer: {
        marginBottom: theme.spacing(2)
    },
    projectIcon: {
        fontSize: theme.spacing(8),
        cursor: "pointer"
    },
    projectItem: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: theme.spacing(20)
    }
}));

export { useStyles };
