import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDisplayProperties } from "../../../store/projects/slice";


const STATE_DIRECTION = {
  FORWARD: "FORWARD",
  REVERSE: "REVERSE"
}

const HIDE_POSTS = "hidePosts";
const HIDE_PROJECTS = "hideProjects";
const DEFAULT_PROJECTS_STATES = [HIDE_POSTS, HIDE_PROJECTS];
const DEFAULT_PROJECTS_STATES_LENGTH = DEFAULT_PROJECTS_STATES.length;

const getDisplayStateCount = (displayProperties) => {
  let count = 0;
  if (displayProperties) {
    for (let i = 0; i < DEFAULT_PROJECTS_STATES_LENGTH; i++) {
      if (displayProperties[DEFAULT_PROJECTS_STATES[i]]) count++;
    }
  }
  return count;
}



const useProjectDisplayState = (projectId) => {
  const dispatch = useDispatch();
  const items = useSelector(state => state.userProjects?.projects?.data?.items);
  const displayProperties = useMemo(() => (projectId && items) ? items[projectId]?.data?.displayProperties : {}, [projectId, items]);
  const { hidePosts = false, hideProjects = false } = displayProperties || {};

  const [loading, setLoading] = useState(false);
  const [direction, setDirection] = useState(STATE_DIRECTION.FORWARD);
  const [stateCount, setStateCount] = useState(null);

  useEffect(() => {
    setStateCount(getDisplayStateCount({ hidePosts, hideProjects }))
  }, [hidePosts, hideProjects]);

  const searchChildren = (projectId, items) => {
    let hasPosts = false;
    let hasProjects = false;
    const children = items[projectId]?.children;
    for (const i in children) {
      const item = items[children[i]];
      if (!hasPosts && item?.data?.postType) {
        hasPosts = true;
      }
      if (!hasProjects && !item?.data?.postType) {
        hasProjects = true;
      }

      if (hasPosts && hasProjects) break;
    }

    return { hasPosts, hasProjects };
  }


  const updateProjectsState = (projectId) => {
    setLoading(true);
    if (items && projectId) {
      let nextStateCount;
      if (stateCount <= 0) {
        nextStateCount = 1;
        setDirection(STATE_DIRECTION.FORWARD);
      } else if (stateCount >= 2) {
        nextStateCount = 1;
        setDirection(STATE_DIRECTION.REVERSE);
      } else {
        nextStateCount = direction === STATE_DIRECTION.FORWARD ? stateCount + 1 : stateCount - 1;
      }

      const { hasPosts, hasProjects } = searchChildren(projectId, items);
      const nextDisplayProps = { ...items[projectId]?.data?.displayProperties };

      setStateCount(nextStateCount);
      for (let i = 0; i < DEFAULT_PROJECTS_STATES_LENGTH; i++) {
        const HIDE = DEFAULT_PROJECTS_STATES[i];
        if (hasPosts && hasProjects) {
          nextDisplayProps[HIDE] = i < nextStateCount;
        } else {
          nextDisplayProps[HIDE] = !nextDisplayProps[HIDE];
        }
      }
      // console.log("nextDisplayProps: " + JSON.stringify(nextDisplayProps, null, 2));
      dispatch(updateDisplayProperties({ id: projectId, displayProperties: nextDisplayProps }));
    }
    setLoading(false);
  }


  const updateHideTitle = (projectId, hideTitle) => {
    setLoading(true);
    if (projectId && hideTitle !== undefined && hideTitle !== null) {
      const nextDisplayProps = {
        ...items[projectId]?.data?.displayProperties,
        hideTitle
      };
      dispatch(updateDisplayProperties({ id: projectId, displayProperties: nextDisplayProps }));
    }
    setLoading(false);
  }

  const updateHideHeader = (projectId, hideHeader) => {
    setLoading(true);
    if (projectId && hideHeader !== undefined && hideHeader !== null) {
      const nextDisplayProps = {
        ...items[projectId]?.data?.displayProperties,
        hideHeader
      };
      dispatch(updateDisplayProperties({ id: projectId, displayProperties: nextDisplayProps }));
    }
    setLoading(false);
  }


  return { loading, updateProjectsState, updateHideTitle, updateHideHeader };
}

export default useProjectDisplayState;