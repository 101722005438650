import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDisplayProperties } from "../../../store/projects/slice";


const useDisplayState = () => {
  const dispatch = useDispatch();
  const items = useSelector(state => state.userProjects?.projects?.data?.items);

  const [loading, setLoading] = useState(false);

  const updateHideTitle = (postId, hideTitle, postType) => {
    setLoading(true);
    if (postId && hideTitle !== undefined && hideTitle !== null) {
      const nextDisplayProps = {
        ...items[postId]?.data?.displayProperties,
        hideTitle
      };
      dispatch(updateDisplayProperties({ id: postId, displayProperties: nextDisplayProps, postType }));
    }
    setLoading(false);
  }

  const updateHideHeader = (postId, hideHeader, postType) => {
    setLoading(true);
    if (postId && hideHeader !== undefined && hideHeader !== null) {
      const nextDisplayProps = {
        ...items[postId]?.data?.displayProperties,
        hideHeader
      };
      dispatch(updateDisplayProperties({ id: postId, displayProperties: nextDisplayProps, postType }));
    }
    setLoading(false);
  }


  return { loading, updateHideTitle, updateHideHeader };
}

export default useDisplayState;