const COLOR = {
  PRIMARY: {
    MAIN: "#A7C1D5",
    DARK: "#87aac5",
    LIGHT: "#bacede",
  },
  BG_PRIMARY: {
    MAIN: "#6C7096",
  },
  ORANGE: {
    MAIN: "#FF7E00",
  },
};

const POST_TYPE = {
  RICH_TEXT: "RICH_TEXT",
  IMAGE: "IMAGE",
};
POST_TYPE.LIST = Object.values(POST_TYPE);

const DIALOG = {
  PROJECT: "PROJECT",
  DOCUMENT_POST: "DOCUMENT_POST",
  IMAGE_POST: "IMAGE_POST",
};

const PROJECT_STATE = {
  CLOSE: "CLOSE",
  CLOSE_PROJECTS: "CLOSE_PROJECTS",
  CLOSE_POSTS: "CLOSE_POSTS",
};

export { COLOR, POST_TYPE, DIALOG, PROJECT_STATE };
