import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Box, Button, Dialog, DialogTitle, DialogContent, TextField, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { WorkboxHeader } from "../../Workbox/";
import { patch } from "../../../util/httpClient";
import { updatePostName } from "../../../store/projects/slice";

import useStyles from "./useStyles";
import useProjectDisplayState from "../../../hooks/project/useProjectDisplayState";



const Header = ({ project }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id, name, displayProperties } = project;

  const { loading: displayStateLoading, updateProjectsState, updateHideTitle, updateHideHeader } = useProjectDisplayState(id);

  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [msg, setMsg] = useState("");

  const handleTitleEditClick = (id) => {
    setOpenDialog(true);
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

  const hasAnError = (field, errors) => {
    return errors.find(err => err.field === field)?.message !== undefined;
  }

  const findErrMsg = (field, errors) => {
    return errors.find(err => err.field === field)?.message;
  }

  const handleChange = (e) => {
    if (msg) {
      setMsg("");
    }
    if (Array.isArray(errors) && errors.length > 0) {
      setErrors([]);
    }
    setFieldValue(e.target.name, e.target.value);
  }

  const { handleSubmit, setFieldValue, getFieldProps } = useFormik({
    initialValues: {
      name: name
    },
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      try {
        const response = await patch(`/api/v1/project/${id}/name`, values);
        setLoading(false);
        setSubmitting(false);
        if (response.ok) {
          setMsg("Updated the file post name.");
          const payload = {
            postId: id,
            name: values.name
          };
          dispatch(updatePostName(payload));
          setTimeout(() => { setMsg(""); }, 3000);
        } else {
          if (response.status === 400) {
            const json = await response.json();
            if (json.errors && Array.isArray(json.errors)) {
              setErrors(json.errors)
            }
          }
        }
      } catch (e) {
        setLoading(false);
        setSubmitting(false);
        console.log(e)
      }
    }
  });

  const handleTitleHideChange = (hide) => {
    updateHideTitle(id, hide);
  }

  return (
    <>
      <WorkboxHeader
        title={project?.name}
        hideTitleOnMinimize={displayProperties?.hideTitle}
        minimize={displayProperties?.hideHeader}
        onHideTitleChange={handleTitleHideChange}
        onTitleClick={() => updateProjectsState(id)}
        onTitleEditClick={handleTitleEditClick}
        onCogClick={() => updateHideHeader(id, !displayProperties?.hideHeader)}
        className={classes.workboxHeader}
        loading={displayStateLoading}
        standAlone={true}
      />
      <Dialog open={openDialog} onClose={handleClose} maxWidth="md">
        <DialogTitle>Update Project Name</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} autoComplete="off" className={classes.formRoot}>
            {msg ? <Alert severity="success">{msg}</Alert> : null}
            <TextField
              className={classes.formField}
              fullWidth
              variant="outlined"
              type="text"
              id="name"
              name="name"
              label="Name"
              placeholder="Update Project Name"
              autoFocus {...getFieldProps("name")}
              error={hasAnError("name", errors)}
              helperText={findErrMsg("name", errors)}
              onChange={handleChange}
            />
            <Box className={classes.formBtnBox}>
              <Button className={classes.formButton} size="large" type="submit" variant="contained" color="primary" disabled={loading}>{loading ? <CircularProgress size={30} thickness={6} /> : "Update"}</Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
}



export default Header;
