import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  postBody: {
    maxHeight: "100%"
    // height: MIN_HEIGTH * 5
  },
  title: {
    padding: theme.spacing(0, 1.5),
    height: 30
  },
  textEditorContainer: {
    padding: "0 4px 4px 4px"
    // margin: theme.spacing(2)
  },
  imagePostContainer: {
    padding: "0 4px 4px 4px"
  },
  imagePostWrapper: {
    padding: "0.1rem",
    // maxHeight: "800px",
    overflow: "hidden",
    // overflowY: "scroll",
    // overflowX: "hidden",
    display: "flex",
    alignItems: "baseline",
    justifyContent: "center"
  },
  imagePost: {
    maxWidth: "100%"
  },
  formRoot: {
    margin: theme.spacing(0, 4, 2),
    padding: theme.spacing(4),
    width: theme.spacing(70)
  },
  formField: {
    marginTop: theme.spacing(2)
  },
  formDivider: {
    marginBottom: theme.spacing(2)
  },
  formBtn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  formBtnBox: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(3)
  }
}));