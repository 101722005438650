import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./reducers/user";
import profileReducer from "./reducers/profile";
import projectReducer from "./reducers/projectReducer";
import profileWorkboxReducer from "./reducers/profileWorkboxReducer";

import userProjectsReducer from "./projects/slice";

export default configureStore({
    reducer: {
        user: userReducer,
        profile: profileReducer,
        projects: projectReducer,
        profileWorkbox: profileWorkboxReducer,

        userProjects: userProjectsReducer
    }
})