import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { AppBar, Toolbar, Button, Grid, CircularProgress, Chip } from '@material-ui/core';

import { setAuthenticated, setName } from "../../store/actions/user";
import { httpGet, post } from "../../util/httpClient";
import ArtisboxLogo from '../../assets/components/ArtisboxLogo';
import BoxesGroupOne from '../../assets/components/BoxesGroupOne';
import BoxesGroupTwo from '../../assets/components/BoxesGroupTwo';
import { useStyles } from './useStyles';
import useProjectDisplayState from '../../hooks/project/useProjectDisplayState';


const Header = ({ authenticated, name, setAuthenticated, setName }) => {

  const history = useHistory();
  const classes = useStyles();
  const [logoutLoading, setLogoutLoading] = useState(false);
  const rootId = useSelector(state => state?.userProjects?.projects?.data?.rootId);
  const { loading, updateProjectsState } = useProjectDisplayState(rootId);

  useEffect(() => {
    if (authenticated && !name) {
      (async () => {
        try {
          const response = await httpGet("/api/v1/person");
          if (response.ok) {
            const json = await response.json();
            await setName(json?.results?.name);
          }
        } catch (e) {
          console.log("error fetching name", e);
        }
      })();
    }
  }, [authenticated, name, setName]);

  const handleLogout = async () => {
    setLogoutLoading(true);
    try {
      const response = await post("/api/v1/auth/logout");
      if (response.ok) {
        await setAuthenticated(false);
        await setName("");
      }
      if (response.status === 400) {
        console.log("logout error");
      }
    } catch (e) {
      console.log("logout error", e);
    }
    setLogoutLoading(false);
  }

  return (
    <AppBar position="sticky" elevation={0} className={classes.appBar}>
      <Toolbar>
        <Grid container justifyContent="space-between">
          <Grid item xs>
            {name && <Chip label={name} onClick={() => updateProjectsState(rootId)} className={classes.usernameChip} disabled={loading} />}
          </Grid>
          <Grid item>
            <BoxesGroupOne style={{ margin: "0 20px", width: "300px" }} />
            <NavLink exact to="/" >
              <ArtisboxLogo />
            </NavLink>
            <BoxesGroupTwo style={{ margin: "0 20px", width: "300px" }} />
          </Grid>
          <Grid item xs container justifyContent="flex-end">
            <Grid item>
              {authenticated
                ? <Button component={NavLink} onClick={handleLogout} to="#" disabled={logoutLoading}> {logoutLoading ? <CircularProgress size={20} /> : "LOGOUT"}</Button>
                : <Button component={NavLink} exact to="/login/openbox" color="inherit">Login</Button>
              }
            </Grid>
            <Grid item>
              <Chip label="EXPLORE" onClick={() => history.push("/explore")} className={classes.exploreChip} />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );

};

const mapStateToProps = (state) => {
  const { authenticated, name } = state.user;
  return {
    authenticated,
    name
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setName: (name) => dispatch(setName(name)),
    setAuthenticated: (authenticated) => dispatch(setAuthenticated(authenticated))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
