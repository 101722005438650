import React from "react";
// import ArtisboxLogo from './../assets/components/ArtisboxLogo';
// import BoxesGroupOne from './../assets/components/BoxesGroupOne';

const Explore = () => {
  return (
    <></>
    // <div className="mw-100">
    //   <div className="container-fluid px-1">
    //     <div className="text-center">
    //       <ArtisboxLogo style={{ width: "500px", height: "150px", verticalAlign: "middle" }} viewBox="0 0 140 80" />
    //     </div>
    //     <div className="text-center">
    //       <BoxesGroupOne />
    //     </div>
    //   </div>
    // </div>
  );
}

export default Explore;