import { put, httpGet, httpDelete } from "./../../util/httpClient";
import uuid from "./../../util/uuid";
export const Action = {
  LOADING_PROJECTS: 'LOADING_PROJECTS',
  SHOW_PROJECTS: 'SHOW_PROJECTS',
  ADD_PROJECTS: 'ADD_PROJECTS',
  IS_DELETING_PROJECT: 'IS_DELETING_PROJECT',
  SET_ACTIVE_PROJECT: 'SET_ACTIVE_PROJECT',
  DELETE_PROJECT: 'DELETE_PROJECT',
  SET_DELETE_ERROR: 'SET_DELETE_ERROR',
  TOGGLE_EXPANDED: 'TOGGLE_EXPANDED',
  SET_CHILDREN: 'SET_CHILDREN',
  SET_CHILDREN_LOADING: 'SET_CHILDREN_LOADING',
  SET_CHILDREN_EXPANDED: 'SET_CHILDREN_EXPANDED',
  SET_PROJECT_WB_HEADER_COLOUR: 'SET_PROJECT_WB_HEADER_COLOUR',
  SET_HAS_CHILDREN: 'SET_HAS_CHILDREN',
}

export const toggleExpanded = (projectId) => {
  return { type: Action.TOGGLE_EXPANDED, projectId };
}

export const setChildren = (projectId, children = []) => {
  return { type: Action.SET_CHILDREN, projectId, children };
}

export const addProjects = (items) => {
  return { type: Action.ADD_PROJECTS, items: items };
}

export const removeProjectFromStore = (projectId) => {
  return { type: Action.DELETE_PROJECT, projectId };
}

/**
 * args = {
 *  srcProject: {},
 *  destProject: {},
 *  onSuccess: callback(),
 *  onFail: callback()
 * }
 */
export const updateDisplayPosition = ({ srcProject, destProject, onSuccess, onError }) => {
  return async (dispatch) => {
    const sourceProjectId = srcProject?.id;
    const destinationProjectId = destProject?.id;
    if (sourceProjectId && destinationProjectId) {
      try {
        await put("/api/v1/project/update-display-position", { sourceProjectId, destinationProjectId });
        if (typeof onSuccess === "function") {
          onSuccess();
        }
      } catch (err) {
        // console.log(err);
        if (typeof onError === "function") {
          onError();
        }
      }
    }
  }
};

/**
 * args = {
 *  projectId: int,
 *  parentProjectId: int,
 *  onSuccess: callback(),
 *  onFail: callback()
 * }
 */
export const addParentProject = ({ projectId, parentProjectId, projectDisplayPositionIndex, onStart, onSuccess, onError }) => {
  return async (dispatch) => {
    if (projectId > 0 && parentProjectId >= 0) {
      try {
        if (typeof onStart === "function") { onStart(); }
        const response = await put('/api/v1/project/put-parent-project.php', { projectId, projectDisplayPositionIndex, parentProjectId });
        if (!response.ok) {
          if (typeof onError === "function") { onError(response.json()); }
          return;
        }

        if (typeof onSuccess === "function") { onSuccess(await response.json()); }

      } catch (err) {
        if (typeof onError === "function") { onError(); }
      }
    }
  }
};

export const showLoadingProjects = (isLoading, projectId) => {
  return { type: Action.LOADING_PROJECTS, isLoading, projectId };
};

export const setShowProjects = (showingProjects) => {
  return { type: Action.SHOW_PROJECTS, showingProjects };
};

// following additional UI related data may live on server side in future
export const addUiDataToProjects = (projectItems) => {
  for (const [id, item] of Object.entries(projectItems)) {
    if (id > 0) {
      item.isChildrenLoading = false;
      item.isExpanded = false;
      // item.headerColour = (item?.hasChildren) ? randomHexLightColorCode() : "";
    }
  }
  return projectItems;
}

// const reverseRootChildren = (tree) => {
//   let treeData = { ...tree };
//   const rootId = treeData?.rootId;
//   let ids = treeData?.items[0]?.children;
//   if (rootId !== undefined && Array.isArray(ids) && ids.length > 0) {
//     ids = ids.reverse();
//     treeData.items[rootId].children = ids;
//   }
//   return treeData;
// }

export const fetchProjects = (showLoading) => {
  return async (dispatch) => {
    if (showLoading) {
      dispatch(showLoadingProjects(true));
    }
    const response = await httpGet("/api/v1/project");
    const json = await response.json();
    const items = json?.results?.items ?? {};
    const itemsWithUiProps = addUiDataToProjects(items);
    // const reveredProjects = reverseRootChildren({ rootId: 0, items: itemsWithUiProps });
    dispatch(addProjects(itemsWithUiProps));
    if (showLoading) {
      dispatch(showLoadingProjects(false));
    }
  }
};

export const updateProjectName = ({ id, name }) => {
  return async (dispatch, getState) => {
    let updated = false;
    try {
      const response = await put(`/api/v1/project/name/${id}?name=${name}`);
      if (response.ok) {
        const json = await response.json();
        const project = json.results ?? {};
        if (project && project.id && project.name) {
          const state = getState();
          const projectId = project.id;
          const items = { ...state.projects.items };
          if (items[projectId]) {
            items[projectId].data.name = project.name;
          }
          dispatch(addProjects(items));
        }
        updated = true;
      }
    } catch (err) {
      console.log(err);
    }

    if (!updated) {
      throw Error("Failed to update project name");
    }

  }
};

export const deleteProject = (projectId) => {
  return async (dispatch, getState) => {

    dispatch(setIsDeletingProject(true));
    try {
      const response = await httpDelete('/api/v1/project', projectId);
      // const json = await response.json();
      // const deletedProject = json.data;
      // const deletedProjectID = parseInt(deletedProject.id);

      // const state = getState();
      // let projects = { ...state.projects.items };
      // if (deletedProjectID) {
      //   delete projects[deletedProjectID];
      // }
      if (response.status === 204) {
        dispatch(removeProjectFromStore(projectId));
      } else {
        dispatch(setDeleteError({
          id: projectId,
          message: `Error deleting project. status ${response.status}`
        }));
      }

      // const newProjects = projects.filter(p => parseInt(p.id) !== deletedProjectID);
      // await dispatch({ type: Action.ADD_PROJECTS, projects: newProjects });

    } catch (e) {
      // console.log('failed to delete');
      dispatch(setDeleteError({
        id: projectId,
        message: 'Error deleting project.'
      }));
    }
    dispatch(setIsDeletingProject(false));

  }
};

export const setIsDeletingProject = (isDeletingProject) => {
  return { type: Action.IS_DELETING_PROJECT, isDeletingProject };
};

export const setIsActive = (projectId, isActive = true) => {
  return async (dispatch, getState) => {
    if (uuid.isValid(projectId)) {
      dispatch({ type: Action.SET_ACTIVE_PROJECT, projectId, isActive });

      const state = getState();
      let parentId = state.projects?.items[projectId]?.data?.parentProjectId ?? state.projects?.rootId;
      const siblings = state.projects?.items[parentId]?.children;
      if (Array.isArray(siblings)) {
        for (const sib of siblings) {
          if (state.projects?.items[sib]?.data?.isActive === true && sib !== projectId) {
            dispatch({ type: Action.SET_ACTIVE_PROJECT, projectId: sib, isActive: false });
            break; // should be always just one isActive
          }
        }
      }

    }
  }
};

export const setDeleteError = (deleteError) => {
  return { type: Action.SET_DELETE_ERROR, deleteError };
};

export const fetchProjectsByParentId = ({ projectId, onStart, onSuccess, onError }) => {
  return async (dispatch, getState) => {
    if (typeof onStart === "function") { onStart(); }

    // if (showLoading) {
    //   dispatch(setChildrenIsLoading(projectId, true));
    // }

    try {
      const response = await httpGet(`/api/v2/project/get.php?parentProjectId=${projectId}`);
      const json = await response.json();

      // let projects = json?.results?.items ?? {};
      // const rootId = json?.results?.rootId;

      // const newItems = addUiDataToProjects(projects);
      // const children = newItems[rootId]?.children ?? [];
      // if (newItems[rootId]) {
      //   delete newItems[rootId];
      // }

      // const state = getState();
      // const items = { ...state.projects.items, ...newItems };
      // items[rootId].children = children;
      // items[rootId].isChildrenLoading = false;
      // items[rootId].isExpanded = true;

      // const headerColour = items[rootId].headerColour;
      // for (const id of children) {
      //   items[id].headerColour = headerColour;
      // }

      if (typeof onSuccess === "function") { onSuccess(json); }

      // dispatch(addProjects(items));
    } catch (err) {
      if (typeof onError === "function") { onError(); }
      // if (showLoading) {
      //   dispatch(setChildrenIsLoading(projectId, false));
      // }
    }

  }
};
export const setChildrenIsLoading = (projectId, isLoading) => {
  return {
    type: Action.SET_CHILDREN_LOADING,
    projectId,
    isLoading
  };
};

export const setChildrenExpanded = (projectId, isExpanded) => {
  // console.log(`setChildrenExpanded projectId=${projectId} - isExpanded=${isExpanded}`);
  return {
    type: Action.SET_CHILDREN_EXPANDED,
    projectId,
    isExpanded
  };
};

export const setProjectWorkBoxHeaderColour = (projectId, headerColour) => {
  return {
    type: Action.SET_PROJECT_WB_HEADER_COLOUR,
    projectId,
    headerColour
  };
};

export const setHasChildren = (projectId, hasChildren) => {
  return {
    type: Action.SET_HAS_CHILDREN,
    projectId,
    hasChildren
  };
};