import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { Container, Grid, Paper, TextField, Typography, Divider, Button, CircularProgress, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab"

import { post } from "../../util/httpClient";
import { setAuthenticated } from "../../store/actions/user";


const useStyles = makeStyles(theme => ({
  formRoot: {
    margin: theme.spacing(4),
    padding: theme.spacing(4),
    width: theme.spacing(70)
  },
  formField: {
    marginTop: theme.spacing(2)
  },
  formDivider: {
    marginBottom: theme.spacing(2)
  },
  formButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));


const LoginForm = () => {

  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const { handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      username: "",
      password: ""
    },
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      try {
        const response = await post("/api/v1/auth/login", values);
        setLoading(false);
        setSubmitting(false);
        if (response.ok) {
          await dispatch(setAuthenticated(true));
          history.push("/profile");
          // window.location.replace("/profile");
        } else {
          await dispatch(setAuthenticated(false));
          if (response.status < 500) {
            setError("Invalid credentials.");
          }
        }
      } catch (e) {
        setLoading(false);
        setSubmitting(false);
        console.log(e)
      }
    }
  });

  const handleChange = (e) => {
    if (error) {
      setError("");
    }
    setFieldValue(e.target.name, e.target.value);
  }

  const hasError = error?.length > 0;

  return (
    <Paper className={classes.formRoot}>
      <Typography variant="h4" component="h4">Log In</Typography>
      <Divider className={classes.formDivider} />
      <form onSubmit={handleSubmit} autoComplete="off">
        <TextField className={classes.formField} required fullWidth variant="outlined" type="username" id="username" name="username" label="Username" error={hasError} onChange={handleChange} />
        <TextField className={classes.formField} required fullWidth variant="outlined" type="password" id="password" name="password" label="Password" error={hasError} onChange={handleChange} />
        <Button className={classes.formButton} fullWidth size="large" type="submit" variant="contained" color="primary" disabled={loading}>{loading ? <CircularProgress size={30} thickness={6} /> : "Log In"}</Button>
        {error ? <Alert severity="error">{error}</Alert> : null}
      </form>
      <Divider className={classes.formDivider} />
      <Typography align="center">Don't have an account? <Link to="/login/newbox">Sign up</Link></Typography>
    </Paper>
  );
}

const SignupForm = () => {

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const history = useHistory();

  const { handleSubmit, getFieldProps, setFieldValue } = useFormik({
    initialValues: {
      fullname: "",
      email: "",
      username: "",
      password: "",
      rePassword: ""
    },
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      setSubmitting(true);
      try {
        const response = await post("/api/v1/auth/signup", values);
        setSubmitting(false);
        setLoading(false);
        if (response.ok) {
          history.push("/login/openbox");
        }
        if (response.status === 400) {
          const json = await response.json();
          if (json.errors && Array.isArray(json.errors)) {
            setErrors(json.errors)
          }
        }
      } catch (e) {
        setSubmitting(false);
        setLoading(false);
        console.log(e);
      }
    }
  });

  const findErrMsg = (field, errors) => {
    return errors.find(err => err.field === field)?.message;
  }

  const hasAnError = (field, errors) => {
    return errors.find(err => err.field === field)?.message !== undefined;
  }

  const handleChange = (e) => {
    if (Array.isArray(errors) && errors.length > 0) {
      setErrors([]);
    }
    setFieldValue(e.target.name, e.target.value);
  }

  return (
    <Paper className={classes.formRoot}>
      <Typography variant="h4" component="h4">Sign Up, It's free!</Typography>
      <Divider className={classes.formDivider} />
      <form onSubmit={handleSubmit} autoComplete="off">
        <TextField className={classes.formField} required fullWidth variant="outlined" type="text" id="fullname" name="fullname" label="Full Name" {...getFieldProps("fullname")} error={hasAnError("fullname", errors)} helperText={findErrMsg("fullname", errors)} onChange={handleChange} />
        <TextField className={classes.formField} required fullWidth variant="outlined" type="text" id="username" name="username" label="Username" error={hasAnError("username", errors)} helperText={findErrMsg("username", errors)} onChange={handleChange} />
        <TextField className={classes.formField} required fullWidth variant="outlined" type="email" id="email" name="email" label="Email" error={hasAnError("email", errors)} helperText={findErrMsg("email", errors)} onChange={handleChange} />
        <TextField className={classes.formField} required fullWidth variant="outlined" type="password" id="password" name="password" label="Password" error={hasAnError("password", errors)} helperText={findErrMsg("password", errors)} onChange={handleChange} />
        <TextField className={classes.formField} required fullWidth variant="outlined" type="password" id="rePassword" name="rePassword" label="Confirm Password" error={hasAnError("rePassword", errors)} helperText={findErrMsg("rePassword", errors)} onChange={handleChange} />
        <Button className={classes.formButton} fullWidth size="large" type="submit" variant="contained" color="primary" disabled={loading}>{loading ? <CircularProgress size={30} thickness={6} /> : "Create"}</Button>
      </form>
      <Divider className={classes.formDivider} />
      <Typography align="center">Got an account? <Link to="/login/openbox">Log In</Link></Typography>
    </Paper>
  );
}

const Login = () => {
  return (
    <Container maxWidth={false}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Route exact path={`/login/newbox`}>
          <SignupForm />
        </Route>
        <Route exact path={[`/login/openbox`, "/login"]}>
          <LoginForm />
        </Route>
      </Grid>
    </Container>
  );
}

export default Login;