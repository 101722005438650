import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Paper, Box, Button, Dialog, DialogTitle, DialogContent, TextField, CircularProgress } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { patch } from "../../../util/httpClient";
import { updatePostName } from "../../../store/projects/slice";
import { POST_TYPE } from "../../../util/constants";
import { useStyles } from "./useStyles";
import PostWorkbox from "../PostWorkbox";

export function ImagePost({ post, position }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id, name, postType, url } = post;

  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [msg, setMsg] = useState("");

  const handleTitleEditClick = (id) => {
    setOpenDialog(true);
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

  const hasAnError = (field, errors) => {
    return errors.find(err => err.field === field)?.message !== undefined;
  }

  const findErrMsg = (field, errors) => {
    return errors.find(err => err.field === field)?.message;
  }

  const handleChange = (e) => {
    if (msg) {
      setMsg("");
    }
    if (Array.isArray(errors) && errors.length > 0) {
      setErrors([]);
    }
    setFieldValue(e.target.name, e.target.value);
  }

  const { handleSubmit, setFieldValue, getFieldProps } = useFormik({
    initialValues: {
      name: name
    },
    onSubmit: async (values, { setSubmitting }) => {
      setLoading(true);
      try {
        const response = await patch(`/api/v1/project/post/file/${id}/name`, values);
        setLoading(false);
        setSubmitting(false);
        if (response.ok) {
          setMsg("Updated the file post name.");
          const payload = {
            postId: id,
            name: values.name
          };
          dispatch(updatePostName(payload));
          setTimeout(() => { setMsg(""); }, 3000);
        } else {
          if (response.status === 400) {
            const json = await response.json();
            if (json.errors && Array.isArray(json.errors)) {
              setErrors(json.errors)
            }
          }
        }
      } catch (e) {
        setLoading(false);
        setSubmitting(false);
        console.log(e)
      }
    }
  });

  return (
    <PostWorkbox
      post={post}
      headerTitle={name}
      onTitleEditClick={handleTitleEditClick}
      position={position}
    >
      <div className={classes.postBody}>
        {postType === POST_TYPE.IMAGE ?
          <Box className={classes.imagePostContainer}>
            <Paper elevation={2}>
              <Box className={classes.imagePostWrapper}>
                <img src={url} className={classes.imagePost} alt="" />
              </Box>
            </Paper>
          </Box>
          : null
        }
      </div>

      <Dialog open={openDialog} onClose={handleClose} maxWidth="md">
        <DialogTitle>Update File Post Name</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} autoComplete="off" className={classes.formRoot}>
            {msg ? <Alert severity="success">{msg}</Alert> : null}
            <TextField
              className={classes.formField}
              fullWidth
              variant="outlined"
              type="text"
              id="name"
              name="name"
              label="Name"
              placeholder="Update Document Name"
              autoFocus {...getFieldProps("name")}
              error={hasAnError("name", errors)}
              helperText={findErrMsg("name", errors)}
              onChange={handleChange}
            />
            <Box className={classes.formBtnBox}>
              <Button className={classes.formButton} size="large" type="submit" variant="contained" color="primary" disabled={loading}>{loading ? <CircularProgress size={30} thickness={6} /> : "Update"}</Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>

    </PostWorkbox>
  );
}